.support-button {
  position: absolute;
  top: 40px;
  left: 50%;
  box-shadow: 0px 4px 20px rgba(15, 15, 15, 0.12);
  padding: 15px 20px;
  border-radius: 40px;
  border-end-end-radius: 0px;
  border-start-end-radius: 60px;
  text-decoration: none;

  // p {
  //   align-self: center;
  //   padding-left: 13px;
  //   padding-right: 13px;
  // }

  span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    // transition: 0.2s;
    // align-self: center;
    // padding-left: 13px;
    // padding-right: 13px;\
    // animation-name: transtionSpan;
    // animation-duration: 2s;
    // animation-delay: 0s;
    padding-left: 80px;
  }

  // &:hover span {
  //   padding-left: 80px;
  // }

  span::after {
    content: "Need help?";
    position: absolute;
    opacity: 1;
    top: 0;
    right: 0px;
    // transition: 0.2s;

    // animation-name: transtionAfter;
    // animation-duration: 2s;
    // animation-delay: 0s;
  }

  // &:hover span::after {
  //   opacity: 1;
  //   right: 0;
  // }
}

/* The animation code */
@keyframes transtionSpan {
  from {
    padding-left: 80px;
  }

  to {
    padding-left: 80px;
  }
}

@keyframes transtionAfter {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

// .support-button:hover p {
//   display: block;
//   animation: fade_in_show 0.5s;
// }

// @keyframes fade_in_show {
//   0% {
//     opacity: 0;
//     transform: scale(0);
//   }

//   100% {
//     opacity: 1;
//     transform: scale(1);
//   }
// }

// /* The element to apply the animation to */
// div {
//   width: 100px;
//   height: 100px;
//   background-color: red;

// }

@media screen and (max-width: 1024px) {
  .support-button {
    left: 75%;
  }
}

@media screen and (max-width: 835px) {
  .support-button {
    left: 70%;
  }
}

@media screen and (max-width: 599px) {
  .support-button {
    left: 70vw;
    bottom: 94%;

    span {
      display: none;
    }

    &:hover span {
      padding-left: 0px;
    }

    span::after {
      content: "";
    }
  }
}
