.App {
  overflow: hidden;
  white-space: pre-line;
}

.tag-container {
  // overflow-y: scroll;
  height: 100% !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.logo {
  height: 39px;
  width: 275px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

p,
h4 {
  margin: 0;
}

.tag- {
  &container {
    display: grid;
    grid-template-columns: 2fr 1fr;
    height: 100vh;
    min-height: 600px;
    position: relative;

    .right-col {
      overflow: hidden;
      position: relative;
      display: flex;
      align-content: flex-end;
      justify-content: flex-end;
      min-height: 100vh;

      &--image {
        height: 80%;
        max-height: 100vh;
        min-height: 700px;
        align-content: flex-end;
        z-index: 1;
        position: relative;
        align-self: flex-end;
        justify-content: flex-end;
      }

      .bg-cirle {
        width: 900px;
        height: 900px;
        border-radius: 450px;
        position: absolute;
        z-index: 0;
        left: -100px;
        overflow: hidden;
        top: 250px;
      }
    }

    .left-col {
      // width: 70%;
      margin: 0;
      // overflow-y: scroll;
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      padding-right: 15%;
      padding-left: 15%;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

.form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 24px;

  .button-footer {
    margin: 20px 0;
    flex-grow: 1;
    grid-area: 4 / 1 / 5 / 3;
    display: flex;
    flex-direction: column;

    button {
      align-self: flex-end;
    }
  }

  // &:nth-child(5) {
  //   grid-column: 1fr;
  // }
}

.company-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  box-shadow: 0px 4px 20px rgba(15, 15, 15, 0.12);
  border-radius: 8px;

  .first {
    border-right: 1px solid #e6e7e8;
  }

  &__child {
    padding: 15px 30px;
  }
}

@media screen and (max-width: 1024px) {
  .tag- {
    &container {
      display: grid;
      grid-template-columns: 1fr;
      height: 100vh;

      .right-col {
        display: none;
      }

      .left-col {}
    }
  }
}

@media screen and (max-width: 599px) {
  .tag- {
    &container {
      display: grid;
      grid-template-columns: 1fr;
      height: 100vh;

      .right-col {
        display: none;
      }

      .left-col {
        padding-right: 7%;
        padding-left: 7%;
      }
    }
  }

  .form {
    grid-template-columns: 1fr;
    position: relative;

    button {
      flex-grow: 1;
      grid-area: 7;
    }
  }

  .company-info {
    grid-template-columns: 1fr;

    .first {
      border-right: none;
      border-bottom: 1px solid #e6e7e8;
    }

    &__child {
      padding: 30px 40px;
    }
  }
}

.input__form--error {
  border: 1px solid #e16161 !important;
}

.row {
  display: flex;
  flex-direction: row;
}

.select__form--prefix {
  border-right: none !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;

  &>div>input:focus {
    border: none !important;
    color: #1c1e1e;
  }

  div>i {
    position: absolute;
    top: 40px;
    left: 55px;
  }
}

.prefix {
  border: none !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  width: 75px;
  cursor: pointer;
  justify-content: center;
  padding: 15px 0px 15px 10px !important;

  .option__default {
    padding: 0 !important;
  }
}

.phone {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  flex-grow: 1;

  border-radius: 4px;
  padding: 15px 10px;
  border: 1px solid #e6e7e8;
  color: #1c1e1e;
  font-family: "NHaasGroteskDSPro-55Rg", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.02em;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &[data-disabled="true"] {
    background-color: #f5f5f5;
    color: #858788;
  }
}

.option__list {
  // top: 75px;
  max-height: 180px !important;
  z-index: 10;
}

.select__form__open {
  border-right: none !important;

  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.show-password {
  cursor: pointer;
  position: absolute;
  top: 40px;
  left: 85%;
}

.password--details {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  justify-content: space-between;

  &-circle {
    width: 10px;
    height: 10px;
    border-radius: 5px;
  }
}




.tag-button__primary--orange {
  background-color: #1B1C1E;
  border-color: #1B1C1E;
}

.tag-bg-gray-50 {
  background-color: #F4F4F4;
}

.tag-bg-gray-200 {}


.tag-c-black {
  color: #1B1C1E;
}

.separator {
  width: 100%;
  height: 1px;
  background-color: #F4F4F4;
}
