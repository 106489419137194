.confirmation {
  .right-col--image {
    width: 400px;
    height: 400px !important;
    min-height: 400px;
    align-content: center !important;
    align-self: center !important;
  }

  .app-container {
    padding: 60px 75px;
    margin-top: 55px;
    margin-bottom: 55px;
    box-shadow: 0px 4px 20px rgba(15, 15, 15, 0.12);
    border-radius: 5px;

    h4 {
      margin-bottom: 14px;
    }

    p {
      margin-bottom: 24px;
    }
    .tag-button {
      text-decoration: none;
      width: 50%;
      text-align: center;

      margin: 0;
    }
  }

  @media screen and (max-width: 599px) {
    .app-container {
      padding: 20px 25px;

      .tag-button {
        width: calc(100vw - 200px);
        margin: auto;
      }
    }
  }
}
